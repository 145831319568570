import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Link from "../components/link"
import { ButtonLink } from "../components/button"
import { Row, Shim, PageTitle } from "../components/row"
import PhotoGrid from "../components/photo-grid"
import SEO from "../components/seo"

const pageTitle = "Desserts"
const ImagesQuery = graphql`
  query {
    signatureDesserts: allFile(
      filter: { relativePath: { regex: "/signature-desserts/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }

    weddingDessertTables: allFile(
      filter: { relativePath: { regex: "/wedding-dessert-tables/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }

    themeDessertTables: allFile(
      filter: { relativePath: { regex: "/theme-dessert-tables/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }

    designerCookies: allFile(
      filter: { relativePath: { regex: "/designer-cookies/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }

    frenchMacarons: allFile(
      filter: { relativePath: { regex: "/french-macarons/" } }
    ) {
      edges {
        node {
          ...customFluid
        }
      }
    }
  }
`

export default () => (
  <Layout>
    <SEO title={pageTitle} />
    <Row>
      <PageTitle>{pageTitle}</PageTitle>
      <StaticQuery
        query={ImagesQuery}
        render={data => {
          return (
            <>
              <PhotoGrid
                name="designerCupcakes"
                images={data.signatureDesserts.edges}
              />
              <Shim>
                <h2>Signature Desserts</h2>
                <p>
                  We offer a variety of decadent desserts at both of our Fresno
                  locations including: strawberry pizzas, pie pockets,
                  cannoli, cake pops, cake slices, french macarons, chocolate
                  chip cookies, and premium vanilla ice cream to enjoy à la mode 
                  or as a Cake Shake. That's right! Choose any cupcake off the 
                  counter and we will blend it with vanilla ice cream – 
                  <em>ridiculous</em>. Stop by our shop for a sweet treat soon.
                </p>
                <p>
                  <ButtonLink
                    to="/desserts/gallery/signature-desserts"
                    data-title="View full gallery"
                  >
                    View full gallery
                  </ButtonLink>

                  <ButtonLink
                    darkgold
                    to="menu"
                    data-title="View this week's menu"
                  >
                    View this week's menu
                  </ButtonLink>
                </p>
              </Shim>
              <PhotoGrid
                name="weddingDessertTables"
                images={data.weddingDessertTables.edges}
              />
              <Shim>
                <h2>Wedding Dessert Tables</h2>
                <p>
                  Looking for a unique way to entertain your guests before the
                  cake cutting? A dessert table may be the best thing since
                  sliced bread! They are great conversation starters and are
                  deliciously fun for you and your guests. Choose from a large
                  variety of miniature or standard size desserts including:
                  cheesecake shooters, cake shooters, cannoli, strawberry
                  pizzas, fruit tartlets, lemon bars, truffle brownies, cream
                  puffs, cake pops, cupcakes and more!
                </p>
                <h3>Pricing &amp; Consultations</h3>
                <p>
                  Most wedding dessert tables include a centerpiece wedding cake
                  for the cake cutting tradition. Dessert Tables are priced per
                  dessert starting at $44/dozen. We recommend 2-3
                  miniature desserts per guest. We suggest a consultation with
                  one of our dessert technicians 3-6 months prior to your
                  event. This gives you a chance to taste test some our
                  products.
                </p>

                <p>
                  <ButtonLink
                    to="/desserts/gallery/wedding-dessert-tables"
                    data-title="View full gallery"
                  >
                    View full gallery
                  </ButtonLink>

                  <ButtonLink
                    darkgold
                    to="https://store.frostedcakery.com/s/appointments"
                    data-title="Book a consultation"
                  >
                    Book a consultation
                  </ButtonLink>
                </p>
              </Shim>
              <PhotoGrid
                name="themeDessertTables"
                images={data.themeDessertTables.edges}
              />
              <Shim>
                <h2>Themed Dessert Tables</h2>
                <p>
                  Themed dessert tables are all the rage birthdays, showers or
                  other social events. Why stop at just a Unicorn Cake when you
                  could pull out all the stops with matching cupcakes, cookies,
                  dipped pretzels, cake pops and more!? These tables are a great
                  focal point for your event. Don't forget to share on your
                  Instagram account and tag us @frostedcakery.
                </p>
                <p>
                  We require a consultation with our Cake Designer prior to your 
                  event. These tables take a lot of planning
                  and attention to detail, so schedule your consultation today.
                </p>

                <p>
                  <ButtonLink
                    to="/desserts/gallery/themed-dessert-tables"
                    data-title="View full gallery"
                  >
                    View full gallery
                  </ButtonLink>

                  <ButtonLink
                    darkgold
                    href="https://store.frostedcakery.com/s/appointments"
                    data-title="Book a consultation"
                  >
                    Book a consultation
                  </ButtonLink>
                </p>
              </Shim>
              <PhotoGrid
                name="designerCookies"
                images={data.designerCookies.edges}
              />
              <Shim>
                <h2>Designer Sugar Cookies</h2>

                <p>
                  Our soft, buttery and flakey sugar cookies are made from
                  scratch with whole ingredients and gourmet almond and vanilla
                  bean paste. Our recipe will be sure to knock your socks off!
                  Most of our delicious designer cookies are decorated with
                  fondant, royal icing detail and our almond silk glaze ...{" "}
                  <em>mmmm</em>. We can create any shape and design you can
                  imagine for birthdays and other special events, such as
                  unicorns, mermaids, and other popular characters! We also
                  offer wedding cookies to be used as favors or on dessert
                  tables. Some of our popular wedding cookie designs are brides,
                  grooms, flowers, monograms, tiered cakes and more! BEWARE: Our
                  awesome cookies are known to go missing by the handful; you
                  may consider a purse check at the door.
                </p>
                <p>
                  <em>Our designer cookies start at $6.50 each, pricing depends on 
                    the complexity of the design and time frame of the order. 
                    Minimum order for each design is 1 dozen and we recommend 
                    at least a 30 day minimum notice.
                  </em>
                </p>
                <p>
                  Come in during our <Link to="contact">store hours</Link> to
                  try one of our Designer Sugar Cookies and tell us what you
                  think – even if you’re not a fan! We offer them daily at both 
                  of our Fresno locations.
                </p>

                <p>
                  <ButtonLink
                    to="/desserts/gallery/designer-cookies"
                    data-title="View full gallery"
                  >
                    View full gallery
                  </ButtonLink>

                  <ButtonLink
                    darkgold
                    to="menu"
                    data-title="View this week's menu"
                  >
                    View this week's menu
                  </ButtonLink>
                </p>
              </Shim>
              <PhotoGrid
                name="frenchMacarons"
                images={data.frenchMacarons.edges}
              />
              <Shim>
                <h2>French Macarons</h2>
                <p>
                  Our French macarons are a unique kind of wonderful! The outer
                  shells are made with almond flour and egg whites which gives
                  them a light, soft texture with a crunch. They are piled high
                  with delicious fillings such as butter creams, fresh fruit and
                  fruit fillings. We offer custom or themed French macarons for
                  all occasions!
                </p>
                <p>
                  Some more regularly available flavors are salted 
                  caramel, white truffle raspberry, confetti and more. We even 
                  dabble in custom flavor requests for our customers, such as 
                  Lavender Truffle or Mint Julep -- yum!
                </p>

                <p>
                  <em>
                    We require a minimum order of 2 dozen per design per flavor.
                    Our pricing starts at $3 each; pricing depends
                    on the complexity of the design and time frame of the order.
                  </em>
                </p>

                <p>
                  Consultations may be necessary. Please call us today to
                  inquire about your French Macaroon order!
                </p>
                <ButtonLink
                  to="/desserts/gallery/french-macarons"
                  data-title="View full gallery"
                >
                  View full gallery
                </ButtonLink>
              </Shim>
            </>
          )
        }}
      />
    </Row>
  </Layout>
)
